import "./App.css";

import Navbars from "./components/Navbars";
import CardSlider from "./components/CardSlider";
import HomeVideo from "./components/HomeVideo";
import Service from "./components/Service";
import Projects from "./components/Projects";
import Slider from "./components/Slider";
import ReviewSlider from "./components/ReviewSlider";
import EnquiryForm from "./components/EnquiryForm";
import Footer from "./components/Footer";
import Floatingicon from "./components/Floatingicon";
import Careers from "./components/Careers";
import Contactus from "./subcomponent/Contactus";
import Aboutus from "./subcomponent/Aboutus";
import SubProjects from "./subcomponent/SubProjects";
import Servicesweoffer from "./subcomponent/Servicesweoffer";
import Gypsum from "./subcomponent/Gypsum";
import Paintingwork from "./subcomponent/PaintingWork";
import AluminiumWork from "./subcomponent/AluminiumWork";
import GlassWork from "./subcomponent/GlassWork";
import FlooringWork from "./subcomponent/FlooringWork";
import CorianWork from "./subcomponent/CorianWork";
import CarpentryWork from "./subcomponent/CarpentryWork";
import Home from "./components/Home";
import ModularWork from "./subcomponent/ModularWork";
import Estimatecosting from "./subcomponent/Estimatecosting";
import Budget from "./subcomponent/Budget";
import Events from "./subcomponent/Events";
import News from "./subcomponent/News";
import Gallary from "./subcomponent/Gallery";
import AllPhotos from "./subcomponent/AllPhotos";
import Staff from "./subcomponent/Staff";
import Team from "./subcomponent/Team";
import Office from "./subcomponent/Office";
import Learnmore from "./subcomponent/Learnmore";
import Tender from "./subcomponent/Tender";
import InvitationLetter from "./subcomponent/InvitationLetter";
import Login from "./subcomponent/Login";
import UserInfo from "./subcomponent/UserInfo";
import Signup from "./subcomponent/Signup";
import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
function App() {

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     navigate("/");
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [navigate]);

  return (
    <div className="App">
      <Navbars />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/homegypsumwork" element={<Gypsum />} />
        <Route path="/homepaintaingwork" element={<Paintingwork />} />
        <Route path="/homealuminiumwork" element={<AluminiumWork />} />
        <Route path="/homeglasswork" element={<GlassWork />} />
        <Route path="/homeflooringwork" element={<FlooringWork />} />
        <Route path="/homecorianwork" element={<CorianWork />} />
        <Route path="/homecarpentrywork" element={<CarpentryWork />} />
        <Route path="/homefurniturework" element={<ModularWork />} />
        <Route path="/homeestimate" element={<Estimatecosting />} />
        <Route path="/homebudget" element={<Budget />} />

        <Route path="aboutus" element={<Aboutus />} />

        <Route path="services" element={<Servicesweoffer />} />

        <Route path="services/gypsumwork" element={<Gypsum />} />
        <Route path="services/paintaingwork" element={<Paintingwork />} />
        <Route path="services/aluminiumwork" element={<AluminiumWork />} />
        <Route path="services/glasswork" element={<GlassWork />} />
        <Route path="services/flooringwork" element={<FlooringWork />} />
        <Route path="services/corianwork" element={<CorianWork />} />
        <Route path="services/carpentrywork" element={<CarpentryWork />} />
        <Route path="services/furniturework" element={<ModularWork />} />
        <Route path="services/estimate" element={<Estimatecosting />} />
        <Route path="services/budget" element={<Budget />} />

        <Route path="projects" element={<SubProjects />} />
        <Route path="projects/subprojects" element={<Learnmore />} />
        <Route path="career" element={<Careers />} />
        <Route path="events" element={<Events />} />
        {/* <Route path='news' element={<News/>}/> */}
        <Route path="contactus" element={<Contactus />} />

        <Route path="gallery" element={<Gallary />}>
          <Route index element={<AllPhotos />} />

          <Route path="allphotos" element={<AllPhotos />} />

          <Route path="staff" element={<Staff />} />
          <Route path="team" element={<Team />} />
          <Route path="office" element={<Office />} />
        </Route>

        <Route path="tender" element={<Tender />}></Route>
        <Route path="tender/detailsoftender" element={<InvitationLetter />} />
        <Route path="tender/login" element={<Login />} />
        <Route path="tender/login/signup" element={<Signup />} />

        <Route path="/userinfo" element={<UserInfo />} />
      </Routes>
      <Floatingicon />
    </div>
  );
}

export default App;
