import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { BiStar } from "react-icons/bi";
import "../css/ReviewSlider.css";
import Carousel from "react-bootstrap/Carousel";
// import ApiConfig from '../subcomponent/ApiConfig';
const Review = () => (
  <div className="review">
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
  </div>
);
function ReviewSlider() {
  const [activeSlide, setActiveSlide] = useState(0);
  useEffect(() => {
    // Get a reference to your carousel element
    const carousel = document.getElementById("myCarousel");
    carousel.addEventListener("slide.bs.carousel", (event) => {
      setActiveSlide(event.to);
    });
  }, []);

  const [testimonials, setTestimonials] = useState([]);
  // const apiUrl =ApiConfig.apiUrl;
  useEffect(() => {
    // Fetch testimonials data from the API
    const fetchTestimonials = async () => {
      try {
        const response = await fetch("https://api.lissomtech.in/get_testimonials");
        // const response = await fetch(`${apiUrl}/get_testimonials`);
        const data = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <div
      className="container"
      style={{ marginTop: "40px", marginBottom: "10px" }}
    >
      <div className="row">
        <h3>What Client Says</h3>
        <div
          id="myCarousel"
          className="carousel slide mb-6"
          data-bs-ride="carousel"
          data-bs-interval="3000"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
            
            </div>

            {testimonials.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <div className="carousel-caption center-text">
                  <p>{testimonial.Comment}</p>
                  <span>
                    {testimonial.Client_name}
                    <p className="role">{testimonial.Client_position}</p>
                    <p className="role">{testimonial.Address}</p>

                    <Review />
                  </span>
                </div>
              </Carousel.Item>
            ))}
          </div>
          <div className="custom-indicators" style={{ marginBottom: "0px" }}>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="0"
              className={`dot ${activeSlide === 0 ? "active" : ""}`}
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="1"
              className={`dot ${activeSlide === 1 ? "active" : ""}`}
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="2"
              className={`dot ${activeSlide === 2 ? "active" : ""}`}
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="3"
              className={`dot ${activeSlide === 3 ? "active" : ""}`}
              aria-label="Slide 4"
            ></button>
          </div>

          <a
            className="carousel-control-prev"
            href="#myCarousel"
            role="button"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true">
              <GrPrevious />
            </span>
          </a>
          <a
            className="carousel-control-next"
            href="#myCarousel"
            role="button"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true">
              {" "}
              <GrNext />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ReviewSlider;
