import React from "react";
import { Card, Button } from "react-bootstrap";

const GalleryCard = ({ image, }) => (
  <Card style={{ width: '18rem',  }}>
    <Card.Img variant="top" src={`data:image/png;base64,${image}`} alt={image} 
     style={{ height: '200px', objectFit: 'cover', width: '100%' }}/>
  
  </Card>
);

export default GalleryCard;